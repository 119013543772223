import "./index.scss";

/**
 * Import modules
 */

import Inputmask from "inputmask/dist/inputmask.min.js";
import { Fancybox } from "@fancyapps/ui";
import Swiper from "swiper/bundle";
import ymaps from "ymaps";
import lozad from "lozad";
import Toastify from "toastify-js";


/**
 * Helpers
 */
//console.log("null");
const cyrValue = (str) => {
  return /[^а-яА-ЯёЁ -]/gi.test(str);
};
const fancyboxShow = (src, type) => {
  Fancybox.show([
    {
      src: src,
      type: type,
      autoFocus: false,
      trapFocus: false,
      placeFocusBack: false,
    },
  ]);
};



const toast = (title = "", text = "", type = "info") => {
  let message, messageTitle, messageText;
  message = document.createElement("div");
  messageTitle = document.createElement("p");
  messageTitle.classList.add("title");
  messageTitle.innerHTML = title;
  messageText = document.createElement("p");
  messageText.classList.add("text");
  messageText.innerHTML = text;
  message.append(messageTitle, messageText);

  Toastify({
    className: type,
    close: true,
    destination: false,
    duration: 3000,
    escapeMarkup: false,
    gravity: "bottom",
    position: "right",
    stopOnFocus: true,
    text: message.innerHTML,
    onClick: () => {},
  }).showToast();
};

/**
 * Fields validation
 */
if (document.querySelector("input[name='agreement']")) {
  document.querySelectorAll("input[name='agreement']").forEach((el) => {
    el.addEventListener("change", (evt) => {
      const status = evt.currentTarget.checked;

      if (status) {
        evt.currentTarget
          .closest("form")
          .querySelector("button")
          .removeAttribute("disabled");
      } else {
        evt.target
          .closest("form")
          .querySelector("button")
          .setAttribute("disabled", "");
      }
    });
  });
}
if (document.querySelectorAll("input[name='name']")) {
  document.querySelectorAll("input[name='name']").forEach((el) => {
    let counter = 0;

    el.addEventListener("keyup", (evt) => {
      if (cyrValue(evt.target.value)) {
        counter++;

        if (counter > 5) {
          toast("Внимание!", "Имя должно быть на русском языке", "warning");
          counter = 0;
        }
      }

      evt.target.value = evt.target.value.replace(/[^а-яА-ЯёЁ -]/gi, "");
    });
  });
}
if (document.querySelector("input[name='phone']")) {
  document.querySelectorAll("input[name='phone']").forEach((el) => {
    let im = new Inputmask({
      mask: "+7 (e99) 999-99-99",
      definitions: {
        e: {
          validator: "[0-7,9]",
        },
      },
    });
    im.mask(el);

    el.addEventListener("blur", (evt) => {
      if (
        evt.currentTarget.value[17] == "_" ||
        evt.currentTarget.value[17] == undefined
      ) {
        toast("Внимание!", "Некорректный номер", "WARNING");
        evt.target.parentNode.classList.add("error");
        evt.target.parentNode.classList.remove("valid");
      } else {
        evt.target.parentNode.classList.add("valid");
        evt.target.parentNode.classList.remove("error");
      }
    });
  });
}
if (document.querySelector("input[required]")) {
  document.querySelectorAll("input[required]").forEach((el) => {
    el.addEventListener("blur", (evt) => {
      if (evt.target.value.length == 0) {
        toast("Внимание!", "Поле обязательно для заполнения", "WARNING");
      }
    });
  });
}
if (
  document.querySelector("input[type='text'], input[type='email'], textarea")
) {
  document
    .querySelectorAll("input[type='text'], input[type='email'], textarea")
    .forEach((el) => {
      el.addEventListener("blur", (evt) => {
        if (evt.target.value.length > 0) {
          evt.target.parentNode.classList.add("valid");
        } else {
          evt.target.parentNode.classList.remove("valid");
        }
      });
    });
}


if (document.querySelector("input[name='file_attach[]']")) {
  document.querySelectorAll("input[name='file_attach[]']").forEach((el) => {
    el.addEventListener("change", (evt) => {
      var filename = el.files[0].name;
        evt.currentTarget.closest("form").querySelector("#file-name").innerText = filename;
        evt.currentTarget.closest("form").querySelector(".form__input-file").classList.add("is-loaded");
      
    });

  });
}

if(document.querySelector(".file-delete")) {
  document.querySelectorAll(".file-delete").forEach((el) => {
    el.addEventListener("click", (evt) => {
      evt.currentTarget.closest("form").querySelector("#file-name").innerText = "";
      evt.currentTarget.closest("form").querySelector(".form__input-file").classList.remove("is-loaded");
      
    });
    
  });
}

/**
 * Form
 */
if (document.querySelector("form.fetch")) {
  document.querySelectorAll("form.fetch").forEach((form) => {
    form.addEventListener("submit", (evt) => {
      evt.preventDefault();
      //let yaGoal = form.querySelector("input[name='ya_goal']").value;

      let phone = form.querySelector("input[name='phone']").value;
      // let fileAttach;
      // if(form.querySelector('input[type=file]')) {
      //   fileAttach = form.querySelector('input[type=file]');
      // }
      // if (fileAttach) {
      //   fileAttach.value = '';
      //   fileAttach.closest('.attachment').querySelector('.file__name').innetText = '';
      //   fileAttach.closest('.attachment').querySelector('.file__info').classList.remove('is-loaded');
      // }

      if (phone.length[17] === "_" || phone.length === 0) {
        document.querySelector("input[name='phone']").classList.add("is-error");
      } else {
        
        if (form.querySelector("input[type='submit']"))
          form
            .querySelector("input[type='submit']")
            .setAttribute("disabled", "");
        if (form.querySelector("button[type='submit']"))
          form
            .querySelector("button[type='submit']")
            .setAttribute("disabled", "");

        let data = new FormData(form);
        mna100("email", data, (r) => {
          if (r.status === 1) {
  
            if (form.querySelector("#file_presentation")) {
              let link = form.querySelector("#file_presentation").value;
              var link_form = document.createElement('a');
    
              link_form.setAttribute('href',link);
              link_form.setAttribute('download','Презентация');
              onload=link_form.click();
            }
  
            Fancybox.close();
            if (form.querySelector("input[type='submit']"))
              form
                .querySelector("input[type='submit']")
                .removeAttribute("disabled", "");
            if (form.querySelector("button[type='submit']"))
              form
                .querySelector("button[type='submit']")
                .removeAttribute("disabled", "");

            form.querySelectorAll("input").forEach((inp) => {
              if (
                inp.type !== "submit" &&
                inp.type !== "checkbox" &&
                inp.type !== "radio" &&
                inp.type !== "hidden"
              ) {
                inp.value = "";
                inp.parentNode.classList.remove("valid");
              }
            });
            if (form.querySelector("textarea")) {
              form.querySelector("textarea").value = "";
              form
                .querySelector("textarea")
                .parentNode.classList.remove("valid");
            }
            if (form.querySelector("input[type='checkbox']")) {
              form.querySelectorAll("input[type='checkbox']").forEach((el) => {
                if (el.name != "agreement") el.checked = false;
              });
            }
            
            setTimeout(() => {
              fancyboxShow("#thanks", "inline");
            }, 150);
            setTimeout(() => {
              Fancybox.close();
            }, 5000);

            if (typeof ym == "function") {
              ym("XXXXXXXX", "reachGoal", yaGoal);
              console.log("Цель достигнута: " + yaGoal);
            }
            if (typeof gtag == "function") {
              //gtag("event", "form_lead", {"event_category": "lead", "event_action": "zayavka"});
            }
          } else {
            toast("Внимание!", "Ошибка ! " + r.message, "WARNING");

            Fancybox.close();

          }
        });
      }
    });
  });
}


/**
 * Modal
 */
if (document.querySelector(".modal-link")) {
  document.querySelectorAll(".modal-link").forEach((el) => {
    el.addEventListener("click", (evt) => {
      evt.preventDefault();

      const button = evt.currentTarget,
        target = button.dataset.dataHref || button.dataset.href,
        popup = document.querySelector(target),
        title = button.dataset.modalTitle,
        content = button.dataset.modalContent,
        footer = button.dataset.modalFooter,
        submit = button.dataset.modalSubmit,
        yagoal = button.dataset.modalYagoal,
        email = button.dataset.modalEmail,
        services = button.dataset.modalServices,
        files = button.dataset.modalFiles;

      if (title) popup.querySelector(".modal__title span").innerHTML = title;
      if (submit) popup.querySelector(".form__submit .button").innerHTML = submit;
      if (yagoal) popup.querySelector("input[name='ya_goal']").value = yagoal;
      if (email) popup.querySelector("input[name='email_title']").value = email;
      if (target) fancyboxShow(target, "inline");
  
      let filesArray;
      let filesStroke
      
      if (files) {
        filesStroke = files.slice(0, -1);
        filesArray = filesStroke.split(',');
      }
      
      if (services) {
        let wrap_select = popup.querySelector(".form__options");
        if(!(wrap_select.classList.contains("filled"))) {
          wrap_select.classList.add("filled");
          const servicesStroke = services.slice(0, -1);
          const servicesArray = servicesStroke.split(',');
          servicesArray.forEach(function (item, index) {
            var div = document.createElement('div');
            div.classList.add("form__options-item");
            div.textContent = item;
            if (files) {
              div.dataset.fileUrl = filesArray[index];
            }
            div.addEventListener("click", function () {
              popup.querySelector("input[name='select_service']").value = item;
              popup.querySelector(".form__select__toggle").innerHTML = item;
              
              if(files) {
                popup.querySelector("input[name='file_presentation']").value = this.dataset.fileUrl;
              }
              
              popup.querySelector(".form__select__toggle").classList.remove("form__select__toggle--opened");
              popup.querySelector(".form__options").classList.remove("form__options--opened");
            });
            wrap_select.appendChild(div);
          })
          
        }
      }
      
    });
  });
}


/**
 * Lazy load
 */
const observer = lozad();
observer.observe();


/**
 * Mobile menu
 */
if (document.querySelector(".header__humburger-btn")) {
  document.querySelector(".header__humburger-btn").addEventListener("click", (evt) => {
    let buttonClasslist = evt.currentTarget.classList;

    buttonClasslist.toggle("active");

    if (buttonClasslist.contains("active")) {
      document.querySelector(".header__mobile").classList.add("active");
      document.querySelector("body").style.overflow = "hidden";
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector(".header__mobile").classList.remove("active");
      document.querySelector("body").style.overflow = "auto";
      document.querySelector("html").style.overflow = "auto";
    }
  });
}
if (document.querySelector(".header__mobile .menu-item-has-children")) {
  document
    .querySelectorAll(".header__mobile .menu-item-has-children")
    .forEach((el) => {
      let row = document.createElement("div");
      row.classList.add("menu-arrow");
      el.querySelector("a").after(row);
      
  
      row.addEventListener("click", (evt) => {
        el.classList.toggle("active");
      });
    });
}



/**
 * Ymaps
 */
if (document.querySelector("#contacts-map")) {
  const maps_observer = lozad(".map-lazy", {
    loaded: (el) => {
      const coords = el.dataset.coords.split(", "),
        apiKey = el.dataset.key,
        icon = el.dataset.icon,
        label = el.dataset.label,
        clientWidth = document.body.clientWidth;

      let center = coords;
      // if (clientWidth > 1199) {
      //   center = [Number(coords[0]), Number(coords[1]) - 0.003];
      // } else if (clientWidth < 992) {
      //   center = [Number(coords[0]) - 0.0001, Number(coords[1])];
      // }

      ymaps
        .load("https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=" + apiKey)
        .then((maps) => {
          const myMap = new maps.Map("contacts-map", {
              center: center,
              zoom: 17,
              controls: ["fullscreenControl", "zoomControl"],
            }),
            myPlacemark = new maps.Placemark(
              coords,
              {
                hintContent: label,
                balloonContent: label,
              },
              {
                iconLayout: "default#image",
                iconImageHref: icon,
                iconImageSize: [100, 100],
                iconImageOffset: [-50, -115],
              }
            );
          myMap.behaviors.disable("scrollZoom");
          myMap.geoObjects.add(myPlacemark);
        });
    },
  });
  maps_observer.observe();
}


if (document.querySelector("#company-map")) {
  let countriesMap,
    objectManager,
    countriesData = [],
    icon = document.querySelector("#company-map").dataset.iconMap;
  
  document.querySelectorAll(".company-map__coord").forEach((city) => {
    countriesData.push({
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          id: city.dataset.id,
          geometry: {
            type: "Point",
            coordinates: city.dataset.coords.split(","),
          },
          options: {
            iconLayout: "default#image",
            iconImageSize: [32, 38],
            iconImageHref: icon,
          },
        },
      ],
    });
  });
  
  // инициализация карты
  ymaps
    .load(
      "https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=db6a9a83-461e-485a-8185-ac08e42c46e1"
    )
    .then((maps) => {
      countriesMap = new maps.Map("company-map", {
        center: [51.660781, 39.200296],
        zoom: 5,
        controls: ["fullscreenControl", "zoomControl"],
      });
      countriesMap.behaviors.disable("scrollZoom");
      
      objectManager = new maps.ObjectManager({
        clusterize: true,
        gridSize: 64,
      });
      objectManager.clusters.options.set(
        "preset",
        "islands#invertedRedClusterIcons"
      );
      countriesMap.geoObjects.add(objectManager);
      
      countriesData.forEach((city) => objectManager.add(city));
    })
    .catch((error) => console.log("Failed to load Yandex Maps", error));
}



/**SCROLL HEADER**/

let scrollPosition;
let header = document.querySelector(".header");

window.addEventListener('scroll', function() {
  
  scrollPosition = window.scrollY;
  
  if (scrollPosition >= 1) {
    header.classList.add('header-fixed');
  } else {
    header.classList.remove('header-fixed');
  }
  
});



